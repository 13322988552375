// @flow

import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import injectSheet from 'react-jss';
import classNames from 'classnames';
import FontAwesomeIcon from '../../design/icons';
import * as Backend from '../../backend/legacyJsonBackend';
import * as colors from '../../design/colors';
import * as focus from '../../design/focusFrame';

const style = {
  container: {
    maxWidth: 939,
  },
  accordionButton: {
    boxSizing: 'border-box',
    color: colors.ns.black,
    width: '100%',
    appearance: 'none',
    outline: 'none',
    background: 'none',
    border: 'none',
    borderBottom: '1px solid #b2b2b2',
    padding: 16,
    fontSize: 20,
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: 16,
    textAlign: 'left',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f4f4f4',
    },
    '&:focus': {
      ...focus.focusFrameInsetButton,
      borderRadius: 4,
    },
  },
  chevron: {
    color: colors.primary.dark,
    transition: 'transform 0.2s',
  },
  chevronOpen: {
    transform: 'rotate(180deg)',
  },
  content: {
    fontSize: 16,
    padding: 16,
  },
};

const Accordion = injectSheet(style)(
  ({
    element,
    classes,
  }: {
    element: Backend.Element,
    classes: { [$Keys<typeof style>]: string },
  }) => {
    //$FlowIssue React hooks not supported in the version of flow (0.78.0)
    const [open, setOpen] = React.useState(false);

    const buttonId = `${element.elementId}-button`;
    const bodyId = `${element.elementId}-body`;

    return (
      <div className={classes.container}>
        <button
          type="button"
          aria-expanded={open ? 'true' : 'false'}
          aria-controls={bodyId}
          id={buttonId}
          onClick={() => setOpen(!open)}
          className={classes.accordionButton}
        >
          <div>{ReactHtmlParser(element.title || '')}</div>
          <div
            className={classNames(classes.chevron, open && classes.chevronOpen)}
          >
            <FontAwesomeIcon icon={'chevron-down'} />
          </div>
        </button>
        {open && (
          <div
            id={bodyId}
            aria-labelledby={buttonId}
            role="region"
            className={classes.content}
          >
            {ReactHtmlParser(element.description || '')}
          </div>
        )}
      </div>
    );
  },
);
export default Accordion;
